export const environment = {
  production: true,
  local: false,
  deploymentRegion: 'Global',
  stsAuthority: 'https://accounts.nikedev.com/',
  clientId: '66ed1506ebc3943bd205cc71c764cc4d',
  clientRoot: 'https://swooshlink-test.passplay.nike-cloud.com/',//'http://localhost:4252',
  clientScope: 'openid nike.digital email phone',
  apiRoot: 'https://accounts.nikedev.com/api/',
  swooshBaseUrl: 'https://passplayapi-test.nike.com',
  termsAndConditions: "https://agreementservice.svs.nike.com/rest/agreement?agreementType=programTerms&country=-country-&language=en&mobileStatus=false&requestType=redirect&uxId=com.nike.commerce.swoosh.web"
};
