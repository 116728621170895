import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProjectVariablesService {
  public clientUrl = '';
  constructor() {
    this.clientUrl = environment.stsAuthority;

  }
}
