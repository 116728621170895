import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AccountsNikeService } from './services/accounts-nike.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlParams } from './interface/url-params';
import { User } from 'oidc-client-ts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwooshService } from './services/swoosh.service';
import { LoaderService } from './services/loader.service';
import { loader } from './interface/loader';
import { Modal } from './interface/modal';
import { environment } from 'src/environments/environment';
import { CONSTANTS } from 'src/Constant';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('content') content;

  regMessage = '';
  retryFlg: boolean = false;
  loader: loader;
  params: UrlParams = {};
  loggedIn: boolean = false;
  modalData: Modal;
  user: User = null;
  tandcLink = '';

  constructor(private accountsService: AccountsNikeService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private swooshService: SwooshService,
    private loaderService: LoaderService) {
    loaderService.loader$.subscribe(res => {
      this.loader = res;
    });
  }

  ngOnInit(): void {
    this.storeQueryParams();
    if (window.location.href.includes('code')) {
      this.loggedIn = true;
      let regStatus = window.sessionStorage.getItem('regStatus');
      if (regStatus === 'true') {
        this.regMessage = '1';
      } else {
        this.loaderService.setLoader({ status: true, message: '1' });
        this.accountsService.signinRedirectCallback().then(user => {
          if (user) {
            this.user = user;
            this.swooshRegistration(user);
          }
        }).catch((error) => {
          this.regMessage = '2';
          this.loaderService.stopLoader();
          this.enableRetry();
        });
      }
    }
  }


  private swooshRegistration(user: User) {
    this.swooshService.swooshRegister(String(user.state), this.user.access_token, this.user.id_token)
      .subscribe({
        next: (res) => {
          this.regMessage = '3';
          window.sessionStorage.setItem('regStatus', 'true');
        }, error: (error: any) => {
          if (error instanceof HttpErrorResponse && (error.status === 409 || error.status === 400)) {
            this.regMessage = error.error;
          } else {
            this.regMessage = '4';
            this.enableRetry();
          }
          window.sessionStorage.setItem('regStatus', 'false');
          this.loaderService.stopLoader();
        },
        complete: () => {
          this.loaderService.stopLoader();
          this.cd.detectChanges();
        }
      });
  }

  Login() {
    if (!this.params.token) {
      this.showModal({ message: '1', title: '1' });
    } else {
      this.accountsService.login(this.params.token, this.params.userId, this.params.language);
    }
  }

  showModal(modal: Modal) {
    this.modalData = modal;
    this.modalService.open(this.content, { centered: true });
  }

  enableRetry() {
    this.loadDataFrmSession();
    if (this.params.token && this.params.userId)
      this.retryFlg = true;
  }

  retry() {
    this.Login();
  }

  loadDataFrmSession() {
    this.params.token = window.sessionStorage.getItem('token');
    this.params.userId = window.sessionStorage.getItem('userId');
  }

  private storeQueryParams() {
    this.route.queryParams.subscribe(params => {
      this.params.token = params['token'];
      this.params.language = params['culture'];
      this.params.userId = params['userId'];
      this.params.country = params['country'];

      //store userId and token in session storage for retry.
      if (this.params.userId)
        window.sessionStorage.setItem('userId', this.params.userId);
      if (this.params.token)
        window.sessionStorage.setItem('token', this.params.token);
      if (this.params.language) {
        if (!RegExp('^[a-z]{2}-[A-Z]{2}$').test(this.params.language))
          this.params.language = "en-US"
        window.sessionStorage.setItem('language', this.params.language);
      }

      if (this.params.language) {
        //Load language
      }

      this.tandcLink = this.generateTAndCLink();
    });
  }

  private generateTAndCLink() {
    if (environment.deploymentRegion === CONSTANTS.DEPLOYMENTREGION.CHINA) {
      return environment.termsAndConditions;
    } else {
      return environment.termsAndConditions.replace('-country-', this.params.country ?? 'US')
    }
  }
}
