import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SigninCallbackComponent } from './signin-callback.component';

export const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'signin-callback', component: SigninCallbackComponent },
  { path: 'app-header', component: HeaderComponent },
];
@NgModule({
  declarations: [],
  imports: [ RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
