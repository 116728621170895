<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="header-break"></div>
</div>
<div *ngIf="!loggedIn" class="container-terms">
  <span class="swoosh-terms terms-hdr">
    <a target="_blank" [href]="tandcLink">
      <h5>{{'SWOOSH_TERMSANDCONDITIONS' | translator}}</h5>
    </a>
  </span>
  <span class="swoosh-terms">{{'SWOOSH_TERMSANDCONDITIONS_TEXT' | translator}}</span>
  <button class="btn btn-primary btn-align" style="width:14rem" type="button" (click)="Login()">
    {{'LINK_ACCOUNT' | translator}}
  </button>
</div>
<div *ngIf="loader?.status" class="overlay">
  <div class="overlay__inner">
    <div class="overlay__content" [ngSwitch]="loader.message"><span class="spinner"></span>
      <span *ngSwitchCase="'1'">{{'SWOOSH_IN_PROGRESS' | translator}}</span>
      <span *ngSwitchDefault>{{loader.message}}</span>
    </div>
  </div>
</div>
<div class="flex-center" [ngSwitch]="regMessage">
  <div *ngSwitchCase="'1'">{{'SWOOSH_ALREADY_LINKED' | translator}}</div>
  <div *ngSwitchCase="'2'">{{'SWOOSH_WENT_WRONG' | translator}}</div>
  <div *ngSwitchCase="'3'">{{'SWOOSH_SUCCESS' | translator}}</div>
  <div *ngSwitchCase="'4'">{{'SWOOSH_FAILED' | translator}}</div>
  <div *ngSwitchDefault>{{regMessage}}</div>
</div>
<div class="flex-center">
  <button *ngIf="retryFlg" class="btn btn-primary btn-align center" style="width:4rem;align-self: center;
  margin-top: 1rem;" type="button" (click)="retry()">
    {{'RETRY' | translator}}
  </button>
</div>
<app-footer></app-footer>
<!--modal -->
<ng-template #content let-modal>
  <div class="modal-header" [ngSwitch]="modalData.title">
    <h4 *ngSwitchCase="'1'" class="modal-title">{{'ERROR' | translator}}</h4>
    <h4 *ngSwitchDefault>{{modalData.title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body" [ngSwitch]="modalData.message">
    <p *ngSwitchCase="'1'">{{ 'MODAL_ERROR' | translator}}</p>
    <p *ngSwitchDefault>{{modalData.message}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">{{'CLOSE' | translator }}</button>
  </div>
</ng-template>