import { Injectable } from '@angular/core';
import { ProjectVariablesService } from './project-variables.service';
import { HttpClient} from '@angular/common/http';
import { User, UserManager,UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AccountsNikeService {
  userManager: UserManager;
  constructor(private http: HttpClient,
      private projVars: ProjectVariablesService) {
      const settings:UserManagerSettings = {
        //monitorSession:true,
        stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
        authority: environment.stsAuthority,
        client_id: environment.clientId,
        redirect_uri: environment.clientRoot,
        silent_redirect_uri: `${environment.clientRoot}silent-callback.html`,
        post_logout_redirect_uri: `${environment.clientRoot}`,
        response_type: 'code',
        scope: environment.clientScope
      };
      this.userManager = new UserManager(settings);
    }
  
    public getUser(): Promise<any> {
      return this.userManager.getUser();
    }
  
    public login(secret:string,userId:string, language:string): Promise<void> {
      return this.userManager.signinRedirect({state:secret,extraQueryParams:{"login_hint":userId,"ui_locales":language}});
    }

    public signinRedirectCallback(): Promise<User> {
      return this.userManager.signinRedirectCallback();
    }
  
    public logout(): Promise<void> {
      return this.userManager.signoutRedirect();
    }

    public userSignedInEvent(signinCallback){
      this.userManager.events.addUserSignedIn(signinCallback);
    }

    public isLoggedIn():Promise<boolean>
    {
      return this.userManager.getUser().then(user=>{
        const isLoggedIn=user && !user.expired;
        return isLoggedIn;
      });
    }

}
