import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Language } from '../interface/language';
import EnglishJson from '../languages/en.json';
import ChineseJson from '../languages/zh-CN.json';
import JapaneseJson from '../languages/ja.json';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  languageFilePath: string = '../languages/';
  language: Language;
  languages: Language[] = 
  [{ name: 'English', translationFile: 'en.json', translations: EnglishJson, culture: 'en-US' },
  { name: 'Simplified Chinese', translationFile: 'zh-CN.json', translations: ChineseJson, culture: 'zh-CN'},
  { name: 'Japanese', translationFile: 'ja.json', translations: JapaneseJson, culture: 'ja-JP' }];


  public getDefaultLanguage() {
    const preferredLanguage = localStorage.getItem('preferred-language');
    let language;
    if (preferredLanguage) {
      language=this.languages.find(x => x.culture === preferredLanguage);
    }
    if(!language){
    language=this.languages.find(x => x.culture === 'en-US');
    }
    return language;
  }

  public getAvailableLanguages() {
    return this.languages;
  }

  public setLanguage(selectedLanuage: Language, onLoad: boolean) {
    this.language = selectedLanuage;

    if (!onLoad) {
      localStorage.setItem('preferred-language', this.language.culture);
      window.location.reload();
    }
  }
}
