import { Component, OnInit } from '@angular/core';
import { Language } from '../interface/language';
import { LanguageService } from '../services/language.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selectedLanguage: Language;
  languages: Language[];
  language: string;
  constructor(public languageService: LanguageService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    let prefLang =localStorage.getItem('preferred-language');
    const url =window.location.href;
    if(url.includes('culture')){  
      const startindex= url.indexOf('culture=')+ ('culture=').length;
      const endindex= startindex+5;
      let lang =url.substring(startindex,endindex);
      if (lang && lang.length === 5){
        this.language = lang;
      }
      else
         this.language = "en-US"
    }
    if(this.language && !prefLang){
      localStorage.setItem('preferred-language',  this.language); 
    }
    this.languages = this.languageService.getAvailableLanguages();
    const defaultLanguage =  this.languageService.getDefaultLanguage();
    this.setLanguage(defaultLanguage, true);
  }
  setLanguage(language: Language, onLoad: boolean) {
    this.selectedLanguage = language;
    this.languageService.setLanguage(language, onLoad);
  }
}
